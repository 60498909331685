
//#region breakpoints
//taken from bootstrap
$breakpoint-xxs: "max-width: 350px"; //img_350
$breakpoint-xs: "max-width: 767px"; //img_768
$breakpoint-sm: "min-width: 768px"; //img_992
$breakpoint-md: "min-width: 992px"; //img_1280
$breakpoint-lg: "min-width: 1280px";  //img_1440
$breakpoint-xl: "min-width: 1440px";  //img_1920
$breakpoint-mac-min: "min-width: 1500px";
$breakpoint-mac-max: "max-width: 1810px";
$breakpoint-xxl: "min-width: 1920px";  //img_3200
//#endregion


//#region colors
$white: #fff;         //rgba(255, 255, 255, 1)
$off-white: #E8E8E8; //rgba(232, 232, 232. 1)
$black: #000;         //rgba(0, 0, 0, 1)

$light-gray: #5f6799;
$dark-gray: #171927;
$med-gray: #3c4161;

$turquoise: #01B9BB;
$turquoise-light: #92e0e0;
$orchid: #617DC6;
$orchid-light: #afc4e8;
$yolk: #F7D22B;
$yolk-light: #f7d788;
$melon: #F25E42;
$melon-light: #f2b0a0;;
$berry: #E26C7A;
$berry-light: #f3bdc5;

$sunset: #F5855B;
$sunset-light: #f5b7a5;
$meringue: #FFD95C;
$meringue-light: #ffe2a8;
//#endregion


$nav-height: 85px;
$footer-height: 92px;
$vh-less-nav: calc(100vh - #{$nav-height});
$vh-less-footer: calc(100vh - #{$footer-height});
$vh-less-nav-footer: calc(100vh - #{$nav-height} - #{$footer-height});

$body-content-width: 60vw;

$font-xxs: 10px;
$font-xs: .75em;
$font-sm: 20px;
$font-md: 30px;
$font-lg: 40px;
$font-xl: 50px;
$font-xxl: 70px;
