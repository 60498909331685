@import '../stylesheets/variables.scss';

@keyframes scrollingImage {
  100% {
    transform: translateY(-66.6666%);
  }
}

.HomePage {
  width: 100%;
  overflow: hidden !important;

  .panel {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  .left-panel,
  .right-panel {
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 45px 0;
    padding: 45px;
  }

  .left-panel {
    border-right: solid 1px;
  }

  .right-panel.panel-title-container {
    flex-wrap: wrap;
  }

  .panel-desc-container {
    p {
      padding: 0 45px;
      margin: 0;
    }

    a {
      text-decoration: none;
      font-weight: bold;
      color: $melon;

      &:hover {
        color: $turquoise;
      }
    }
  }

  .HeroPanel {
    min-height: 100vh;
    background: $sunset-light;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    & > div {
      width: 100%;
    }

    .hero-title {
      font-size: 7em;
      color: $berry;
      margin-top: auto;
    }

    .hero-links {
      display: flex;
      justify-content: center;
      align-self: flex-start;

      a {
        font-size: 3em;
        margin: 0 10px;
        color: $berry;

        &:hover {
          color: $sunset;
        }
      }
    }
  }

  .AboutPanel {
    background: $turquoise-light;

    .left-panel {
      border-right-color: $orchid;

      .subtitle {
        color: $orchid;
      }
    }

    .right-panel {

      .panel-title {
        color: $orchid;
      }
    }
  }

  .SkillsPanel {
    background: $orchid-light;

    .left-panel {
      border-right-color: $melon;
    }

    .right-panel {
      display: block;

      img {
        width: 100px;
        height: auto;
        margin: 15px;
        filter: drop-shadow(2px 2px 4px rgba(24, 43, 114, 0.4));

        &.zoom {
          transition: transform .5s ease;

          &:hover {
            transform: scale(1.25);
          }
        }

      }
    }
  }

  .PortfolioPanel {
    background: $yolk;

    .left-panel {
      border-right-color: $turquoise;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .scrolling-image {
        width: 375px;
        height: 215px;
        overflow: hidden;
        margin: 15px;

        img {
          object-fit: cover;
          width: inherit;
          animation: scrollingImage 15s linear infinite;

          &.fast {
            animation: scrollingImage 5s linear infinite;
          }

          &.med {
            animation: scrollingImage 10s linear infinite;
          }
        }
      }

      .non-scrolling-image {
        width: 375px;
        height: 215px;
        overflow: hidden;
        margin: 15px;

        img {
          object-fit: cover;
          width: inherit;
          height: inherit;
        }
      }

    }

    .right-panel {
      flex-wrap: wrap;

      .panel-title {
        color: $turquoise;
      }
    }
  }

  .Footer {
    padding: 40px;
    background: $sunset-light;
    color: $melon;
    font-weight: bold;
  }


  //#region breakpoints
  @media only screen and ($breakpoint-xs) {
    .left-panel,
    .right-panel {
      margin: 0;
      padding: 30px;
    }

    .HeroPanel {
      .hero-title {
        font-size: 5em;
      }
    }

    .panel-title-container {
      width: auto;
      margin: 0 auto;
    }

    .panel-desc-container {
      width: auto;

      p {
        padding: 0;
      }
    }

    .SkillsPanel {
      flex-wrap: wrap;

      .left-panel {
        border-right: none;
        border-bottom: solid 1px $melon;
      }

      .right-panel {
        width: auto;

        &.md {
          display: block;
        }

        &.lg {
          display: none;
        }

        img {
          width: 60px;
        }
      }
    }

    .PortfolioPanel,
    .AboutPanel {
      display: flex;
      flex-wrap: wrap-reverse;

      .left-panel {
        border-right: none;
        width: auto;

        .scrolling-image,
        .non-scrolling-image {
          width: 300px;
        }
      }

      .right-panel {
        border-bottom: solid 1px $turquoise;
      }
    }

    .PortfolioPanel {

      .left-panel {
        padding-right: 0;
        padding-left: 0;
      }
    }

  }

  @media only screen and ($breakpoint-sm) {

    .panel-desc-container {
      p {
        padding: 0;
      }
    }

    .SkillsPanel {
      .right-panel {

        &.md {
          display: block;
        }

        &.lg {
          display: none;
        }

        img {
          width: 60px;
        }
      }
    }

    .PortfolioPanel {

      .left-panel {

        .scrolling-image,
        .non-scrolling-image {
          width: 300px;
        }
      }
    }
  }

  @media only screen and ($breakpoint-md) {

    .panel-desc-container {
      p {
        padding: 0 45px;
      }
    }

    .PortfolioPanel {

      .left-panel {

        .scrolling-image,
        .non-scrolling-image {
          width: 375px;
        }
      }
    }

  }

  @media only screen and ($breakpoint-lg) {

    .SkillsPanel {

      .right-panel {

        &.md {
          display: none;
        }

        &.lg {
          display: block;
        }
      }
    }

    .PortfolioPanel {

      .left-panel {

        .scrolling-image,
        .non-scrolling-image {
          width: 375px;
        }
      }
    }

  }

  @media only screen and ($breakpoint-mac-min) and ($breakpoint-mac-max) {

    .PortfolioPanel {

      .left-panel {

        .scrolling-image,
        .non-scrolling-image {
          width: 295px;
        }
      }
    }
  }

  @media only screen and ($breakpoint-xl) {

    .SkillsPanel {

      .right-panel {

        img {
          width: 85px;
        }
      }
    }
  }
  //#endregion
}
