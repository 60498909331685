@import 'variables';
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Montserrat&display=swap');

html {
  //height: 100%;
}

body {
  font-family: 'Montserrat', cursive;
  margin: 0;
  //width: 100% !important;
  //min-width: 100% !important;
  //height: 100%;
  background-color: $sunset-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $off-white;
  //overflow-x: hidden;
  font-weight: 400;

  #root {
    height: 100%;
  }

  .App {
    height: 100%;

    .navbar.navbar-default {
      margin-bottom: 0;
    }

  }


  //#region text/fonts
  .title,
  .title p,
  .hero-title,
  .panel-title {
    font-family: 'Abril Fatface', cursive;
    font-size: $font-lg;
    //font-weight: bold;
    margin: 0;
    padding: 20px 0;
    color: $melon;
    font-weight: 500;
    line-height: 1.2;

    &.xl {
      font-size: $font-xl;
    }

    &.md {
      font-size: $font-md;
    }
  }

  .panel-title {
    padding: 0;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .subtitle {
    font-family: "Montserrat", sans-serif;
    font-size: $font-md;
    margin-bottom: 20px;
    color: $melon;
  }

  p {
    line-height: 1.2;
    padding-bottom: 10px;
    margin: 0;

    &.title {
      line-height: 1.4;
    }
  }

  a {
    transition: color 0.2s ease-in, background 0.2s ease-in;

    &:hover {
      transition: color 0.2s ease-in, background 0.2s ease-in;
    }
  }

  a,
  input {
    &:focus,
    &:active {
      outline: none !important;
    }
  }


  //#region customs crollbar
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $light-gray;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $med-gray;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $med-gray;
  }
  //#endregion

  //#region mobile
  @media only screen and ($breakpoint-xs) {
    .title,
    .title p {
      font-size: $font-md;
      padding-bottom: 15px;

      &.xl {
        font-size: $font-lg;
      }

      &.md {
        font-size: $font-md;
      }
    }

    .panel-title {
      font-size: 3.25em;
      text-align: center !important;
    }

    .subtitle {
      font-size: 20px;
    }

    .navbar-header {
      float: left;
    }

  }

  @media only screen and ($breakpoint-sm) {
    .subtitle {
      font-size: 20px;
    }

    .panel-title {
      font-size: 5em;
    }
  }

  @media only screen and ($breakpoint-md) {
    .subtitle {
      font-size: 25px;
    }

    .panel-title {
      font-size: 6em;
    }
  }

  @media only screen and ($breakpoint-lg) {
    .subtitle {
      font-size: 30px;
    }
  }

  @media only screen and ($breakpoint-xl) {

  }
  //#endregion

}
